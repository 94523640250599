const urls = Object.freeze({
  prod: 'https://courrier-api-test.testaristarc.com',
  dev: 'https://courrier-api-test.testaristarc.com',
});

const baseUrl = process.env.NODE_ENV !== 'production' ? urls.dev : urls.prod;

const constants = Object.freeze({
  baseURL: baseUrl,
  apiURL: `${baseUrl}/api/v2`,
  googleMapsURL: 'https://maps.googleapis.com/maps/api',

  HTTPStatusCode: {
    100: 'Continue',
    101: 'Switching Protocols',
    102: 'Processing',
    200: 'OK',
    201: 'Created',
    202: 'Accepted',
    203: 'Non-Authoritative Information',
    204: 'No Content',
    205: 'Reset Content',
    206: 'Partial Content',
    300: 'Multiple Choices',
    301: 'Moved Permanently',
    302: 'Found',
    303: 'See Other',
    304: 'Not Modified',
    305: 'Use Proxy',
    306: 'Unused',
    307: 'Temporary Redirect',
    308: 'Permanent Redirect',
    400: 'Bad Request',
    401: 'Unauthorized',
    402: 'Payment Required',
    403: 'Forbidden',
    404: 'Not Found',
    405: 'Method Not Allowed',
    406: 'Not Acceptable',
    407: 'Proxy Authentication Required',
    408: 'Request Timeout',
    409: 'Conflict',
    410: 'Gone',
    411: 'Length Required',
    412: 'Precondition Failed',
    413: 'Request Entity Too Large',
    414: 'Request-URI Too Long',
    415: 'Unsupported Media Type',
    416: 'Requested Range Not Satisfiable',
    417: 'Expectation Failed',
    418: 'I\'m a teapot',
    422: 'Unprocessable Entity',
    428: 'Precondition Required',
    429: 'Too Many Requests',
    431: 'Request Header Fields Too Large',
    451: 'Unavailable For Legal Reasons',
    500: 'Internal Server Error',
    501: 'Not Implemented',
    502: 'Bad Gateway',
    503: 'Service Unavailable',
    504: 'Gateway Timeout',
    505: 'HTTP Version Not Supported',
    511: 'Network Authentication Required',
    520: 'Web server is returning an unknown error',
    522: 'Connection timed out',
    524: 'A timeout occurred',
  },
});

export const LIST_OF_ROLES = {
  1: 'standardiste',
  2: 'secretaire',
  3: 'directeur_general',
  4: 'directeur_service',
  5: 'agent_service',
};

export const LIST_OF_ROLES_KEYS = {
  AGENT_SERVICE: 'agent_service',
  DIRECTEUR_GENERAL: 'directeur_general',
  DIRECTEUR_SERVICE: 'directeur_service',
  SECRETAIRE: 'secretaire',
  STANDARDISTE: 'standardiste',
};

export const USER_ROLES = { 1: 'Standardiste', 2: 'Secretaire', 3: 'Directuer General', 4: 'Directuer Service', 5: 'Agent Service' };

export const WORKFLOW_STATE = {
  CLOSED: 'closed',
  PENDING: 'pending',
  PROCESSED: 'processed',
  SENT: 'sent',
  TRANSMITTED_GENERAL_MANAGER: 'transmitted_general_manager',
  TRANSMITTED_SECRETARY: 'transmitted_secretary',
  TRANSMITTED_SERVICE_AGENT: 'transmitted_service_agent',
  TRANSMITTED_SERVICE_MANAGER: 'transmitted_service_manager',
};

export const WORKFLOW_STATE_KEY = {
  closed: 'Classé',
  pending: 'En attente',
  processed: 'Traité',
  sent: 'Expédié',
  transmitted_general_manager: 'Transmis au DG',
  transmitted_secretary: 'Transmis au secretaire',
  transmitted_service_agent: 'En cours',
  transmitted_service_manager: 'Imputé',
};

export default constants;
