import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from "yup";
import Alert from "react-bootstrap/Alert";
import { Login } from "./components/Login";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../redux/reducers/authentication/actionCreators";
import { useFormik } from "formik";
import {
  selectLoginError,
  selectLoginIsLoading,
} from "../../../redux/reducers/authentication/selectors";

const StyledContainer = styled.div`
  align-items: center;
  background-image: url(${toAbsoluteUrl("/media/backgrounds/bck_red.png")});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
`;

const LoginForm = styled.div`
  align-items: center;
  background: #ffffff;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 3px 17px 37px 4px rgba(106, 153, 73, 0.6);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 80%;
  color: #717171;

  .logo {
    height: 65px;
    margin-bottom: 100px;
    width: auto;
  }

  h3 {
    color: #717171;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }

  p {
    color: #717171;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 32px;
  }

  @media only screen and (min-width: 768px) {
    width: 50%;

    .logo {
      height: 90px;
    }
  }
`;

const Title = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 50%;
  padding: 48px;
  padding-top: 168px;

  p {
    color: #ffffff;
    font-size: 50px;
    font-weight: 600;
    line-height: 75px;
    margin: 0;
    width: 450px;
  }
`;

const BasicLoginForm = styled.div`
  width: 430px;
  margin-bottom: 15px;
`;

function AuthLayout() {
  useEffect(() => {
    document.body.classList.add("bg-white");
    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);

  const dispatch = useDispatch();
  const loginError = useSelector(selectLoginError);
  const isLoading = useSelector(selectLoginIsLoading);

  const { handleSubmit, getFieldProps, errors, isValid } = useFormik<{
    email: string;
    password: string;
  }>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .trim()
        .email("Wrong email format")
        .required("Email is required"),
      password: Yup.string().trim().required("Password is required"),
    }),
    onSubmit: async (values) => {
      dispatch(login(values));
    },
  });

  return (
    <StyledContainer>
      <LoginForm>
        <img
          alt="Logo"
          src={toAbsoluteUrl("/media/logos/logo-aristarc.png")}
          className="logo"
        />
        <h3>Connexion</h3>
        <p>Entrez votre login et mot de passe</p>
        <BasicLoginForm>
          {loginError?.result?.error && (
            <Alert variant="danger">{loginError?.result?.error}</Alert>
          )}
          <Form
            onSubmit={handleSubmit}
            validated={isValid}
            noValidate
            className="was-validated"
          >
            <FloatingLabel
              controlId="floatingInput"
              label="Email"
              className="mb-3"
            >
              <Form.Control type="email" {...getFieldProps("email")} required />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingPassword"
              label="Mot de passe"
              className="mb-3"
            >
              <Form.Control
                type="password"
                {...getFieldProps("password")}
                required
              />
            </FloatingLabel>
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>

            <div className="text-center d-grid gap-2">
              <Button
                type="submit"
                size="lg"
                variant="primary"
                disabled={isLoading}
              >
                {isLoading ? "Loading…" : "Se connecter"}
              </Button>
            </div>
          </Form>
        </BasicLoginForm>
        <p>Ou connectez vous avec: </p>
        <Outlet />
      </LoginForm>
      <Title>
        <p>Gestion des Registres de Courriers</p>
      </Title>
    </StyledContainer>
  );
}

function AuthPage() {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='login' element={<Login />} />
        <Route index element={<Login />} />
      </Route>
    </Routes>
  )
}

export {AuthPage}
